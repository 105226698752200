<template>
  
  <div class="mo-reserve-wrap" style="padding-top:7px" v-if="errChkBool">
    
    <div style="padding:30px" v-show="RESULT_MODE == 0">
      <Survey 
        ref="Survey"
        :RESULT_MODE="this.RESULT_MODE"
        :PAGE_MODE="this.PAGE_MODE"
        :PAGE_MODE2="'M'"
        :SURVEY_AF_DATAS="this.SURVEY_AF_DATAS"
        :SURVEY_TYPE="this.SURVEY_TYPE"
        @surveySaveBf="fnSurveySaveBf"
        @surveyEvent="fnSurveySave"
        @closeSurvey="fnClosePage"
        @surveyCmpDatas="fnSurveyCmpDatas"
        @surveyCmpAnsDatas="fnSurveyCmpAnsDatas"
      >
      </Survey>
    </div>

    <div class="reserve-form-wrap--keypad" v-show="RESULT_MODE == 1">

      <div class="mo-reserve-wrap--survey-form type-reserve" style="margin-top:50px;">
        <div class="mo-reserve-wrap--survey-form-list" style="padding-bottom:0">
          <div class="mo-reserve-wrap--survey-form-title required">예약일을 선택해주세요.</div>
          <div class="mo-reserve-wrap--survey-form-a">
            <div class="mo-reserve-wrap--reserve">
              <div class="mo-reserve-wrap--reserve--time">{{ IS_REV ? BF_CNSL_RSVT_DT : '' }}</div>
              <button type="button" class="btn-round" @click="fnFindDatetime">{{ IS_REV ? '수정' : '선택' }}</button>
            </div>
          </div>
        </div>
        <div class="mo-reserve-wrap--survey-form-list" style="padding-bottom:5px" v-show="SEL_SHOW">
          <div class="mo-reserve-wrap--survey-form--calendar">
            <div class="calendar-wrap" style="width:100%;margin:10px 0 10px 0">
              <div class="calendar-modal">
                <v-date-picker
                  v-model=SEL_DATE
                  full-width
                  no-title 
                  color="#fae100"
                  locale="ko-KR"
                  :first-day-of-week="0"
                  :day-format="mixin_getDate"
                  :min=DATE_MIN
                  :max=DATE_MAX
                  @change="fnSelDate"
                >
                </v-date-picker>
              </div>
            </div>

            <div class="mo-reserve-wrap--survey-form--timepick-wrap">
              <div class="mo-reserve-wrap--survey-form--timepick" v-for="(item, index) in BTN_TIME_LIST" :key="item.CTIME" @click="fnSelTime(item)">
                <div class="mo-reserve-wrap--survey-form--timepick-inner"
                  v-if="item.REMAIN<=0" 
                  style="box-shadow: none !important; background-color: #80808070;" 
                  :class="{ 'selected': item.selected&&item.REMAIN>0 }" 
                  :disabled="item.REMAIN<=0?true:false"
                >
                  <strong>{{ item.CTIME }}</strong>
                  <span v-if="REMA_NUM_PEP_ON_OFF && item.REMAIN<=0" style="font-size: 9px; color:#bd0000de;">{{ '예약마감' }}</span>
                </div>

                <div class="mo-reserve-wrap--survey-form--timepick-inner" 
                  v-if="item.REMAIN>0" 
                  style="box-shadow: none !important;" 
                  :class="{ 'selected': item.selected&&item.REMAIN>0 }" 
                  :disabled="item.REMAIN<=0?true:false"
                >
                  <strong>{{ item.CTIME }}</strong>
                  <span v-if="REMA_NUM_PEP_ON_OFF && item.REMAIN>0" style="font-size: 10px; color:#0e0404d1;">{{ '잔여 ' + item.REMAIN }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mo-reserve-wrap--survey-form-list" style="padding-top:25px;">
          <div class="mo-reserve-wrap--survey-form-title required">방문예약</div>
          <div class="mo-reserve-wrap--survey-form-a">
            <div class="mo-reserve-wrap--reserve">
              <div class="mo-reserve-wrap--reserve--time"> 
                {{ CHOICE_DAY_TIME }}
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="mo-reserve-wrap--btn type-free">
        <div class="mo-reserve-wrap--btn" v-if="!BTN_BOOL"> <!-- 설문이 존재하지 않을 경우 -->
          <button v-if="CANCEL_BOOL" type="button" class="btn-primary" style="background:#F1B02A;" @click="fnRevCancelConfirm">예약취소</button>
          <button v-if="PASS_REV_BOOL" type="button" class="sendBtnCla" @click="fnRevSave">제출하기</button>
        </div>
        <div class="mo-reserve-wrap--btn" v-if="BTN_BOOL"> <!-- 설문이 존재하는 경우 -->
          <button v-if="CANCEL_BOOL" type="button" class="btn-primary" style="background:#F1B02A;" @click="fnRevCancelConfirm">예약취소</button>
          <button v-if="PASS_REV_BOOL" type="button" class="sendBtnCla" @click="fnRevSave">제출하기</button>
        </div>
      </div>
    </div>

    <div class="reserve-form-wrap--keypad" v-show="RESULT_MODE == 2">
      <div class="mo-reserve-wrap--title" style="margin-top:50px;">참여완료</div>
      <div class="mo-reserve-wrap--container">
        <div class="mo-reserve-wrap--done-chk">방문 예약이 완료 되었습니다.</div>
        <div class="mo-reserve-wrap--print">
          <div v-for="(item, index) in PRINT_LIST" :key="index"  class="mo-reserve-wrap--print-list" >
            <strong class="mo-reserve-wrap--print-list-title">{{ item.LIST_TITLE }}</strong>
            <span class="mo-reserve-wrap--print-list-txt">{{ item.LIST_TEXT }}</span>
          </div>
        </div>
        <div class="mo-reserve-wrap--print-subtxt">예약을 변경하시려면 <u>변경</u> 버튼을 선택해 주세요.</div>
      </div>
      <div class="mo-reserve-wrap--btn">
        <button type="button" class="btn-primary" @click="fnCaleCha">변경</button>
        <button type="button" @click="fnCloseBrwsAndEVApi" style="background-color: #F1B02A;">확인</button>
      </div>
    </div>

    <div class="reserve-form-wrap--keypad" v-show="RESULT_MODE == 3">
      <div class="mo-reserve-wrap--title" style="margin-top:50px;">취소완료</div>
      <div class="mo-reserve-wrap--container">
        <div class="mo-reserve-wrap--done-chk">방문 예약이 취소되었습니다.</div>
        <div class="mo-reserve-wrap--print">
          <div v-for="(item, index) in PRINT_LIST" :key="index"  class="mo-reserve-wrap--print-list" >
            <strong class="mo-reserve-wrap--print-list-title">{{ item.LIST_TITLE }}</strong>
            <span class="mo-reserve-wrap--print-list-txt">{{ item.LIST_TEXT }}</span>
          </div>
        </div>
      </div>
      <div class="mo-reserve-wrap--btn">
        <button type="button" @click="fnCloseBrws" style="background-color: #F1B02A;">확인</button>
      </div>
    </div>

  </div>
</template>

<script>
  import axios from "axios"
  import { mapState, mapGetters, mapActions } from "vuex";
  import Survey from "@/components/Survey";

  export default {
    name: "MENU_CAM0600_co", //name은 'MENU_' + 파일명 조합
    components: {
      Survey
    },
    metaInfo() {
      return {
          title: "예약하기",
      }
    },
    data() {
      return {
        errChkBool:true,
        BTN_BOOL:true,
        CHOICE_DAY_TIME:'',
        cmpList: [],
        custItem: [],
        custCnt: 0,
        startTime: '', //근무시작시간
        endTime: '', //근무종료시간
        spTime: 60, //상담분리타임(분)
        dateArr: [],
        dayByTimeManArr: [],
        saveYn:true,
        SURVEY_TYPE:'TYPE-02',

        USER_ID:'',
        PAGE_TITLE : '',
        CMP_ID : '',    //캠페인ID
        CMP_NAME : '',    //캠페인nm
        BUSS_NAME : '',    //사업지nm
        CUST_ID : '',   //고객ID
        SEL_DATE : '',  //예약일자
        WEEKDAY:'',
        DATE_MIN : '',  //최소선택가능일자
        DATE_MAX : '',  //최대선택가능일자
        BTN_TIME_LIST : [],
        SEL_TIME : '',
        REV_DT : '',
        REV_VIEW_DT : '',
        SEL_SHOW : false,
        IS_REV : false,
        SAME_DATE_CHK : '',

        CMP_CNSL_ID:'',
        COMP_LINK: '',
        NEW_CMP_CNSL_ID:'',
        BUSS_CODE:'',
        CUST_PHONE:'',
        CUST_NAME:'',
        MKTG_AGRE_YN:'Y',
        PRSN_AGRE_YN:'Y',
        PLUS_FRED_KEY:'',
        VIST_GUB_CD:'CNSL_VISIT_CD_01',
        QUTN_ID:null,
        QUTN_ITEM_LIST:'',
        CHK_QUTN_RPLY:'',
        SEL_QUTN_RPLY:'',
        TXT_QUTN_RPLY:'',
        ETC_QUTN_RPLY:'',
        BF_CNSL_RSVT_DT:'',
        IMG_QUTN_RPLY:'',
        DATE_QUTN_RPLY:'',
        UPLD_GROUP_ID:'',

        alertMsg:{
          error:'관리자에게 문의하세요.',
          confirmSave:'고객님 예약 하시겠습니까?',
          beforeSaveChk:'현장방문 미등록 고객입니다.\n등록 후 상담예약을 진행해주세요.',
          advanReservSave:'사전예약 날짜를 변경하시겠습니까?',
          doNotRes:'해당 시간은 이미 예약이 마감됐습니다.',
          doCounsNotRes:'해당 일자는 이미 상담을 받으신 일자입니다.',
          endCampMsg:'종료된 캠페인입니다.',
          visitCormMsg:"고객님 방문예약 하시겠습니까?",
          visitModiCormMsg:"고객님 방문예약을 변경 하시겠습니까?",
          cancelConfirmMsg:"고객님 방문예약을 취소하시겠습니까?",
          reChkDateTimeMsg:"고객님 예약하려는 일자 및 시간을 선택해주세요.",
          resChkMsg:"고객님 다시 방문예약 해 주시기 바랍니다.",
        },

        RESULT_MODE:-1,
        PAGE_MODE: 'M', //모바일
        START_DATE:'',
        END_DATE:'',
        PRINT_LIST: [],
        SURVEY_AF_DATAS:[],
        REMA_NUM_PEP_ON_OFF:[], //잔여인원 보여짐 여부
        CANCEL_YN:'',
        CANCEL_BOOL: false,
        PASS_REV_BOOL:true,

        resBotData:'',
        resCnslId:'',

        SAVE_BOOL:false,

        PART_TYPE: '',
        CTCP_CODE: '',
        BUSS_CODE: '',
      }
    },

    mounted() {
      $('head').append('<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0">');

      //인입 시점
      window.addEventListener('beforeunload', this.unLoadEvent);

      this.fnOneLoad();

      //카카오 인앱브라우저 벽돌현상처리
      if (window.location.hash) {
        this.init();
      }

    },

    //탈출 시점
    beforeUnmount() {
      window.removeEventListener('beforeunload', this.unLoadEvent);
    },

    methods: {

      //탈출 시점 함수
      unLoadEvent: function (event) {
        event.returnValue = '';
      },

      fnCaleCha() {
        this.fnConnCmp();
        this.RESULT_MODE=1;
        this.SEL_DATE='';
        this.BTN_TIME_LIST=[];
        this.CHOICE_DAY_TIME='';
      },

      fnSurveySaveBf(num) {
        this.RESULT_MODE=num;
      },
      
      fnConfirmChk() {
        let msg='';
        if(this.IS_REV) {
          msg=this.alertMsg.visitModiCormMsg;
        }else{
          msg=this.alertMsg.visitCormMsg;
        }
        
        var _ua = window.navigator.userAgent || window.navigator.vgjendor || window.opera;
        if(/iPad|iPhone|iPod/.test(_ua)) {
          this.common_confirm(msg, this.fnYesCall, null, null, null, 'chk');
        }else{
          if(!confirm(msg)) {
            this.fnDontCall();
          }
        }
      },

      fnYesCall() {
        this.fnNoCall();
      },

      fnDontCall() {
        var _ua = window.navigator.userAgent || window.navigator.vgjendor || window.opera;
        if (_ua.toLocaleLowerCase().indexOf("kakaotalk") > -1) { // 카카오 브라우저로 실행 시킨 경우
          window.location.href = (/iPad|iPhone|iPod/.test(_ua)) ? "kakaoweb://closeBrowser" : "kakaotalk://inappbrowser/close";
        }else{
          window.open('about:blank','_self').self.close();
        }
        this.fnNoCall();
      },

      fnNoCall() {
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: false,
        });
      },

      fnOneLoad() {
        window.onload = function () {
          if(!window.location.hash) {
            window.location = window.location + '#loaded';
            window.location.reload();
          }
        }
      },

      async init() {
        this.fnNoCall();

        //파라미터 수신
        if(!this.mixin_isEmpty(this.$route.query.cmp_id)) {
          this.CMP_ID = this.$route.query.cmp_id.replace(/[\"\']/gi,'');   //캠페인ID
        }
        if(!this.mixin_isEmpty(this.$route.query.buss_code)) {
          this.BUSS_CODE = this.$route.query.buss_code.replace(/[\"\']/gi,'');   //사업지 코드
        }
        if(!this.mixin_isEmpty(this.$route.query.mktg_agre_yn)) {
          this.MKTG_AGRE_YN = this.$route.query.mktg_agre_yn.replace(/[\"\']/gi,''); //마케팅 동의 여부
        }
        if(!this.mixin_isEmpty(this.$route.query.prsn_agre_yn)) {
          this.PRSN_AGRE_YN = this.$route.query.prsn_agre_yn.replace(/[\"\']/gi,''); //개인정보 수집 동의 여부
        }
        if(!this.mixin_isEmpty(this.$route.query.cust_phone)) {
          this.CUST_PHONE = this.$route.query.cust_phone.replace(/[\"\']/gi,''); //고객 핸드폰번호
        }
        if(!this.mixin_isEmpty(this.$route.query.plus_fred_key)) {
          this.PLUS_FRED_KEY = this.$route.query.plus_fred_key.replace(/[\"\']/gi,'');  //플러스 친구 사용키
        }
        if(!this.mixin_isEmpty(this.$route.query.cancel_yn)) {
          this.CANCEL_YN = this.$route.query.cancel_yn.replace(/[\"\']/gi,''); //취소가능 여부
        }

        this.fnConnCmp();
        this.USER_ID = this.user_id;

      },

      dayConvert(e) {
        const day = e.split("-");
        return `${day[0]}.${day[1]}.${day[2]}`;
      },

      getWeekDay(dateDay) {
        const daysOfWeek = ['일', '월', '화', '수', '목', '금', '토'];
        let i = new Date(dateDay).getDay(dateDay);
        return daysOfWeek[i];
      },

      async fnConnCmp() {
        let requestData = {
          headers: {
            SERVICE:this.initHeaders.SERVICE,
            METHOD:'data',
            TYPE:this.initHeaders.TYPE,
            URL:"/api/hlw/advanReserv/campaign/manage/data",
          },
          sendData: {}
        };

        requestData.sendData["CMP_ID"]=this.CMP_ID;
        requestData.sendData["BUSS_CODE"]=this.BUSS_CODE;
        requestData.sendData["CUST_ID"]=this.CUST_ID;
        requestData.sendData["CUST_PHONE"]=this.CUST_PHONE;
        requestData.sendData["CUST_USER_KEY"]=this.PLUS_FRED_KEY;

        //api 호출 부분
        let responseGridList = await this.common_postCall(requestData);
        let resHeader = responseGridList.HEADER;
        let dataCmpList = responseGridList.DATA_CMP_LIST;
        let dataCust = responseGridList.DATA_CUST;
        let dataCustInfo = responseGridList.DATA_CUST_INFO;

        // 데이터 조회 오류 시 alert
        if (resHeader.ERROR_FLAG) {
          if (!this.mixin_isEmpty(responseGridList.HEADER.MSG)) {
            this.common_alert(responseGridList.HEADER.MSG, "error");
          } else {
            this.common_alert(this.alertMsg.error, "error");
          }
          this.errChkBool=false;
          setTimeout(() => this.fnCloseBrws(), 1000);
          return false;
        }else{

          let trgtGubCd='';
          if(dataCust.length>0) {
            trgtGubCd=dataCust[0].TRGT_GUB_CD;
          }

          if( (this.SAVE_BOOL && trgtGubCd=='CUST_TRGT_CD_01') || !this.SAVE_BOOL) {
            this.SAVE_BOOL=false;
            this.errChkBool=true;
            this.getInitCallBack(dataCmpList, dataCust, dataCustInfo);
          }else if(this.SAVE_BOOL && trgtGubCd!='CUST_TRGT_CD_01'){
            this.RESULT_MODE=-1;
            this.common_alert(this.alertMsg.resChkMsg, 'chk');
            setTimeout(() => this.fnCloseBrws(), 1000);
          }
        }
      },

      fnFindDatetime(){
        if(this.SEL_SHOW) {
          this.SEL_SHOW = false;
        }else{
          this.SEL_SHOW = true;
        }
      },
      
      fnSelTime(item){
        if(item.REMAIN<=0){
          this.saveYn=false
          this.BTN_TIME_LIST.forEach((element, i) => {
            element.selected = false;
          });

          this.SEL_TIME='';
          this.CHOICE_DAY_TIME=this.SEL_DATE+' ('+this.WEEKDAY+') '+this.SEL_TIME;
          this.REV_DT=this.SEL_DATE + ' ' + this.SEL_TIME;
          document.getElementsByClassName('sendBtnCla')[0].style.backgroundColor='#EDEDED';

          this.common_alert(this.alertMsg.doNotRes);

        }else{
          this.BTN_TIME_LIST.forEach((element, i) => {
            if(this.BTN_TIME_LIST.indexOf(item)!==i){
              element.selected = false;
            }else{
              this.BTN_TIME_LIST[this.BTN_TIME_LIST.indexOf(item)].selected = true;
            }
          });

          this.SEL_TIME=item.CTIME;
          this.CHOICE_DAY_TIME=this.SEL_DATE+' ('+this.WEEKDAY+') '+this.SEL_TIME;
          this.REV_DT=this.SEL_DATE + ' ' + this.SEL_TIME;
          this.saveYn=true;
        }
      },

      fnSelDate() {
        if(!this.mixin_isEmpty(this.SEL_DATE)) {
          this.SEL_TIME='';
          this.BTN_TIME_LIST=[];
          this.WEEKDAY=this.getWeekDay(this.SEL_DATE);
          this.CHOICE_DAY_TIME=this.SEL_DATE+' ('+this.WEEKDAY+') ';
          const timeRangeList=this.dayByTimeManArr[this.SEL_DATE].timeRangeList.split(',');
          this.startTime=timeRangeList[0];
          this.endTime=timeRangeList[timeRangeList.length-1];
          let maxReservTimeCnt=this.dayByTimeManArr[this.SEL_DATE].maxReservTimeCnt; //시간별 최대 예약 건수

          this.fnConnCust(this.startTime, this.endTime, maxReservTimeCnt, timeRangeList);
          
          this.REV_DT=this.SEL_DATE + ' ' + this.SEL_TIME;
          document.getElementsByClassName('sendBtnCla')[0].style.backgroundColor='#EDEDED';

          setTimeout(() => $('.v-btn--active').removeClass('theme--light'), 11);
          setTimeout(() => $('.v-btn--active').removeClass('v-btn--active'), 11);
        }
      },

      async fnConnCust(stTime, edTime, maxReservTimeCnt, timeRangeList) {
        let requestData = {
          headers: {
            SERVICE:this.initHeaders.SERVICE,
            METHOD:'data',
            TYPE:this.initHeaders.TYPE,
            URL:"/api/hlw/advanReserv/campaign/manage/cust/data",
          },
          sendData: {}
        };

        requestData.sendData["CMP_ID"]=this.CMP_ID;
        requestData.sendData["RESERV_DAY"]=this.SEL_DATE;
        requestData.sendData["ST_TIME"]=stTime;
        requestData.sendData["ED_TIME"]=edTime;

        //api 호출 부분
        let responseGridList = await this.common_postCall(requestData);
        let resHeader = responseGridList.HEADER;
        let resData = responseGridList.DATA;

        // 데이터 조회 오류 시 alert
        if (resHeader.ERROR_FLAG) {
          this.common_alert(this.alertMsg.error, "error");
          return false;
        }else{
          this.BTN_TIME_LIST=[];
          const retDatas=resData;
          let cnt=0;
          
          timeRangeList.forEach(element => {
            let retData='';
            let timeByCnt=0;
    
            let oTime = {};
            oTime.CTIME=element;
            
            if(oTime.CTIME.includes(':')) {
              let timeArr=oTime.CTIME.split(':');
              if(timeArr.length>1) {
                oTime.VTIME=timeArr[0]+'시'+timeArr[1]+'분';
              }
            }
    
            const todayDate=new Date();
            
            if(retDatas.length>0){
              retData=retDatas[cnt];
              if(!this.mixin_isEmpty(retData)){
                if(retData.CNSL_RSVT_TIME===oTime.CTIME){
                  timeByCnt=retData.TIME_BY_CNT;
                  cnt++;
                }else{
                  
                  //예약시간 정합성 체크
                  let revTimeChkBool=false
                  timeRangeList.forEach(trlTime => {
                    if(retData.CNSL_RSVT_TIME==trlTime){
                      revTimeChkBool=true;
                    }
                  });
    
                  if(!revTimeChkBool && !this.mixin_isEmpty(retDatas[cnt+1])) {
                    cnt++;
                  }
                }
                
              }
            }
    
            oTime.REMAIN=maxReservTimeCnt-timeByCnt;
            let choiceDate=new Date(this.SEL_DATE+' '+oTime.CTIME);
            if(oTime.REMAIN<=0||todayDate>choiceDate){
              oTime.REMAIN=0;
            }
    
            if(!this.mixin_isEmpty(oTime.CTIME)) {
              this.BTN_TIME_LIST.push(oTime);
            }
          });
        }

      },

      fnRevCancelConfirm() {
        this.common_confirm(this.alertMsg.cancelConfirmMsg, this.fnRevCancel, null, null, null, 'chk');
      },

      async fnRevCancel() {
        let requestData = {
          headers: {
            SERVICE:this.initHeaders.SERVICE,
            METHOD:'data',
            TYPE:this.initHeaders.TYPE,
            URL:"/api/hlw/advanReserv/campaign/manage/advanceReser/cmpCust/cancel",
          },
          sendData: {}
        };

        requestData.sendData["CMP_CNSL_ID"]=this.CMP_CNSL_ID;
        requestData.sendData["CMP_ID"]=this.CMP_ID;
        requestData.sendData["BUSS_CODE"]=this.BUSS_CODE;
        requestData.sendData["CUST_ID"]=this.CUST_ID;
        requestData.sendData["CUST_PHONE"]=this.CUST_PHONE;
        requestData.sendData["CUST_NAME"]=this.CUST_NAME;
        requestData.sendData["MKTG_AGRE_YN"]='Y';
        requestData.sendData["PRSN_AGRE_YN"]='Y';
        requestData.sendData["CNSL_RSVT_DT"]=this.REV_DT;
        requestData.sendData["CNSL_DAY"]=this.SEL_DATE;
        requestData.sendData["CNSL_TIME"]=this.SEL_TIME;
        requestData.sendData["CUST_USER_KEY"] = this.PLUS_FRED_KEY; //카카오 개인 key
        requestData.sendData["PLUS_FRED_KEY"] = this.PLUS_FRED_KEY; //카카오 개인 key 두개셋

        var _ua = window.navigator.userAgent || window.navigator.vgjendor || window.opera;
        requestData.sendData["DEVICE_MODEL"]=_ua;

        if(this.mixin_isEmpty(this.QUTN_ID)){
          this.QUTN_ID=null;
        }
        requestData.sendData["REG_ID"]=this.USER_ID;

        //api 호출 부분
        let responseGridList = await this.common_postCall(requestData);
        let resHeader = responseGridList.HEADER;
        this.resBotData = responseGridList.DATA;
        this.resCnslId = responseGridList.DATA_CNSL_ID;

        // 데이터 조회 오류 시 alert
        if (resHeader.ERROR_FLAG) {
          const msg = this.resBotData[0].MSG;
          this.common_alert(msg, "error");
          return;
        }else{

          this.PRINT_LIST=[
            {
              LIST_TITLE: '내용',
              LIST_TEXT: this.BUSS_NAME+' 방문예약 취소',
            },
            {
              LIST_TITLE: '기간',
              LIST_TEXT: this.START_DATE + ' ~ ' + this.END_DATE
            },
            {
              LIST_TITLE: '일시',
              LIST_TEXT: this.BF_CNSL_RSVT_DT,
            },
          ];

          //@AFTER 완료 페이지 이동
          this.RESULT_MODE=3;
        }

        // 알림톡
        if(this.resBotData.length>0 && this.resCnslId.length>0){
          this.sendMessage("CANCEL_NOTI");
          // this.sendEventApi(this.resBotData, this.resCnslId, "DELETE");
        }
      },

      async sendMessage(MSG_TMPL_TYPE) {
        
        // await this.mixin_alarmTalk_token();
        let requestData = {
          headers: {},
          sendData: {},
        };

        let token;

        //header 세팅
        requestData.headers["URL"] = "/api/hlw/campaign/alarmTalk-msgformat/manage/list";
        requestData.headers["SERVICE"] = "hlw.campaign.alarmTalk-msgformat.manage";
        requestData.headers["METHOD"] = "list";
        requestData.headers["ASYNC"] = false;

        //sendData 초기화
        requestData.sendData["BUSS_CODE"] = this.BUSS_CODE;              //사업지 아이디
        requestData.sendData["CMP_ID"] = this.CMP_ID;                   //캠페인 아이디
        requestData.sendData["CUST_PHONE"] = this.CUST_PHONE;
        requestData.sendData["CUST_USER_KEY"] = this.PLUS_FRED_KEY;
        requestData.sendData["MSG_TMPL_TYPE"] = MSG_TMPL_TYPE;           //메세지 속성 코드(사전예약 취소) 알림톡

        let response = await this.common_postCall(requestData);

        if (!response.HEADER.ERROR_FLAG) {

          let data = response.DATA;
          let data_btn = response.DATA_BTN;
          let custData = response.CUST_DATA;

          if (data != null && data.length > 0) {

            let msgFormat = data[0].MSG_CONT;  //메세지내용

            // const todayDate = new Date();
            // let yyyy = todayDate.getFullYear();
            // let mm = todayDate.getMonth() + 1 > 9 ? (todayDate.getMonth() + 1) : '0' + (todayDate.getMonth() + 1);
            // let dd = todayDate.getDate() > 9 ? todayDate.getDate() : '0' + todayDate.getDate();
            // let hour = todayDate.getHours() > 9 ? todayDate.getHours() : '0' + todayDate.getHours();
            // let minute = todayDate.getMinutes() > 9 ? todayDate.getMinutes() : '0' + todayDate.getMinutes();

            // const todayTime = yyyy + '-' + mm + '-' + dd + ' ' + hour + ':' + minute;

            msgFormat = msgFormat.replace(/#{사업지명}/g, data[0].BUSS_NAME)
              .replace(/#{고객명}/g, custData[0].CUST_NAME)
              // .replace(/#{예약일시}/g, todayTime);
              .replace(/#{예약일시}/g, this.CHOICE_DAY_TIME == '' ? this.BF_CNSL_RSVT_DT : this.CHOICE_DAY_TIME);
              

            // let sendMsgFormat = this.decode(msgFormat);

            let setButtons = [];
            let setquickButtons = [];

            let dataBtnLen = (typeof data_btn != undefined && typeof data_btn != "undefined") ? data_btn.length : 0;
            let i = 0;

            if (dataBtnLen > 0) {

              for (; i < dataBtnLen; i++) {
                if (data_btn[i].BTN_TYPE == "MSG_BTN_TYPE_CO") {
                  //일반 버튼(button)
                  setButtons.push({
                    name: data_btn[i].BTN_NAME,
                    type: data_btn[i].BTN_LINK_TYPE,
                    url_mobile: data_btn[i].BTN_LINK_URL
                  });
                } else {
                  //바로가기 버튼(Quick_reply)
                  setquickButtons.push({
                    name: data_btn[i].BTN_NAME,
                    type: data_btn[i].BTN_LINK_TYPE,
                    url_mobile: data_btn[i].BTN_LINK_URL
                  });
                }
              }
            }
            const sendButton = { "button": setButtons };

            let requestAlarmTalkData = {
              headers: {},
              sendData: {},
            };

            let buttonToString = `'${JSON.stringify(sendButton)}'`;

            requestAlarmTalkData.headers["URL"] = "/api/hlw/campaign/alarmTalk-postmsg/manage/post";
            requestAlarmTalkData.headers["SERVICE"] = "hlw.campaign.alarmTalk-postmsg.manage";
            requestAlarmTalkData.headers["METHOD"] = "post";
            requestAlarmTalkData.headers["ASYNC"] = false;

            requestAlarmTalkData.sendData["CMP_ID"] = this.CMP_ID;
            requestAlarmTalkData.sendData["TRAN_SENDER_KEY"] = data[0].SNDR_KEY;
            requestAlarmTalkData.sendData["TRAN_TMPL_CD"] = data[0].MSG_TMPL_CODE;
            requestAlarmTalkData.sendData["TRAN_PHONE"] = this.CUST_PHONE.replaceAll("-", "");;
            requestAlarmTalkData.sendData["TRAN_CALLBACK"] = data[0].SNDR_PHONE.replaceAll("-", "");
            requestAlarmTalkData.sendData["TRAN_MSG"] = msgFormat;
            requestAlarmTalkData.sendData["TRAN_REPLACE_MSG"] = msgFormat;
            if(sendButton["button"].length != 0) requestAlarmTalkData.sendData["TRAN_BUTTON"] = this.encode(buttonToString);

            let alarmTalkResponse = await this.common_postCall(requestAlarmTalkData);
            if (alarmTalkResponse.HEADER.ERROR_FLAG) {
              this.common_alert("관리자에게 문의해주세요.", "error");
            }
          }
        }
      },

      decode(strText) {

        //값존재유무 체크
        if (strText === "" || strText == null) {
          return strText;
        }

        strText = strText.toString();

        //문자열 길이가 4이상일 경우만 처리
        if (strText.length <= 3) {
          return strText;
        }

        //순서바뀌면 안나옴
        strText = strText.replaceAll("&lt;", "<");
        strText = strText.replaceAll("&gt;", ">");
        strText = strText.replaceAll("&amp;", "&");
        strText = strText.replaceAll("&quot;", '"');
        strText = strText.replaceAll("&apos;", "'");
        strText = strText.replaceAll("<br>", "\r");
        strText = strText.replaceAll("<p>", "\n");
        strText = strText.replaceAll("&#91;", "[");
        strText = strText.replaceAll("&#93;", "]");
        strText = strText.replaceAll("&#40;", "(");
        strText = strText.replaceAll("&#41;", ")");

        return strText;
      },
      encode(strText) {
      // 값 존재 유무 체크
      if (strText === "" || strText == null) {
        return strText;
      }

      strText = strText.toString();

      // 문자열 길이가 4 이상일 경우만 처리
      if (strText.length <= 3) {
        return strText;
      }

      // 문자열을 HTML 엔티티로 인코딩
      strText = strText.replaceAll("&", "&amp;");
      strText = strText.replaceAll("<", "&lt;");
      strText = strText.replaceAll(">", "&gt;");
      strText = strText.replaceAll('"', "&quot;");
      strText = strText.replaceAll("'", "&apos;");
      strText = strText.replaceAll("\r", "<br>");
      strText = strText.replaceAll("\n", "<p>");
      strText = strText.replaceAll("\[", "&#91;");
      strText = strText.replaceAll("\]", "&#93;");
      strText = strText.replaceAll("\(", "&#40;");
      strText = strText.replaceAll("\)", "&#41;");

      return strText;
    },
      fnRevSave() {
        
        if(this.mixin_isEmpty(this.REV_DT) || this.mixin_isEmpty(this.SEL_TIME) || this.mixin_isEmpty(this.CHOICE_DAY_TIME)){
          this.common_alert(this.alertMsg.reChkDateTimeMsg, "chk");
          return;
        }else{
          if(!this.mixin_isEmpty(this.REV_DT)&&this.REV_DT.includes(' ')) {
            let revDtArr=this.REV_DT.split(' ');

            if(revDtArr.length>1) {
              let time=revDtArr[1].replace(':', '');
              if(Number(time)<=0) {
                this.common_alert(this.alertMsg.reChkDateTimeMsg, "chk");
                return;
              }
            }else{
              this.common_alert(this.alertMsg.reChkDateTimeMsg, "chk");
              return;
            }
          }
        }

        //현재 선택한 날짜
        //이전에 사전 예약 후 상담받은 날짜
        let bool=false;
        let cust='';
        if(this.custItem.length>0) {
          for (let index = 0; index < this.custItem.length; index++) {
            cust = this.custItem[index];

            if(index==0) {
              if(this.mixin_isEmpty(this.NEW_CMP_CNSL_ID)&&cust.VIST_GUB_CD=='CNSL_VISIT_CD_02') { //방문완료 데이터일 경우
                this.CMP_CNSL_ID='';
              }else if(!this.mixin_isEmpty(this.NEW_CMP_CNSL_ID)){
                this.CMP_CNSL_ID=this.NEW_CMP_CNSL_ID;
              }
            }

            const cnslDayTime=cust.CNSL_RSVT_DT.split(' ');
            const dateDay=cnslDayTime[0];

            //선택된 일자가 방문완료일 경우 방문확인으로 해당날짜 예약 불가능
            if(this.SEL_DATE==dateDay&&cust.VIST_GUB_CD!='CNSL_VISIT_CD_01'){
              bool=true;
            }
          }
        }else{
          this.CMP_CNSL_ID=this.NEW_CMP_CNSL_ID;
        }

        if(this.SEL_DATE===this.SAME_DATE_CHK || bool){
          this.common_alert(this.alertMsg.doCounsNotRes, "chk");
          return;
        }

        if(!this.saveYn){
          this.common_alert(this.alertMsg.doNotRes, 'chk');
          return;
        }

        let msg=this.alertMsg.confirmSave;
        if(this.IS_REV){
          msg=this.alertMsg.advanReservSave;
        }

        this.common_confirm(msg, this.fnConnCmpCustRegist, null, null, null, 'chk');
      },

      callNo() {
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: false,
        });
      },
      
      async fnConnCmpCustRegist() {
        this.callNo();

        //제출하기 버튼 비활성화
        this.PASS_REV_BOOL=false;

        let requestData = {
          headers: {
            SERVICE:this.initHeaders.SERVICE,
            METHOD:'data',
            TYPE:this.initHeaders.TYPE,
            URL:"/api/hlw/advanReserv/campaign/manage/advanceReser/cmpCust/regist",
          },
          sendData: {}
        };

        requestData.sendData["CMP_CNSL_ID"]=this.CMP_CNSL_ID;
        requestData.sendData["CMP_ID"]=this.CMP_ID;
        requestData.sendData["PART_TYPE"]=this.PART_TYPE;
        requestData.sendData["CTCP_CODE"]=this.CTCP_CODE;
        requestData.sendData["BUSS_CODE"]=this.BUSS_CODE;
        requestData.sendData["CUST_ID"]=this.CUST_ID;
        requestData.sendData["CUST_PHONE"]=this.CUST_PHONE;
        requestData.sendData["CUST_NAME"]=this.CUST_NAME;
        requestData.sendData["MKTG_AGRE_YN"]='Y';
        requestData.sendData["PRSN_AGRE_YN"]='Y';
        requestData.sendData["CNSL_RSVT_DT"]=this.REV_DT;
        requestData.sendData["CNSL_DAY"]=this.SEL_DATE;
        requestData.sendData["CNSL_TIME"]=this.SEL_TIME;
        requestData.sendData["CUST_USER_KEY"] = this.PLUS_FRED_KEY; //카카오 개인 key
        requestData.sendData["PLUS_FRED_KEY"] = this.PLUS_FRED_KEY; //카카오 개인 key 두개셋

        requestData.sendData["QUTN_ITEM_LIST"] = this.QUTN_ITEM_LIST;
        requestData.sendData["CHK_QUTN_RPLY"] = this.CHK_QUTN_RPLY;
        requestData.sendData["SEL_QUTN_RPLY"] = this.SEL_QUTN_RPLY;
        requestData.sendData["TXT_QUTN_RPLY"] = this.TXT_QUTN_RPLY;
        requestData.sendData["ETC_QUTN_RPLY"] = this.ETC_QUTN_RPLY;
        requestData.sendData["IMG_QUTN_RPLY"] = this.IMG_QUTN_RPLY;
        requestData.sendData["DATE_QUTN_RPLY"] = this.DATE_QUTN_RPLY;
        requestData.sendData["UPLD_GROUP_ID"] = this.UPLD_GROUP_ID;

        if(this.mixin_isEmpty(this.QUTN_ID)) {
          this.QUTN_ID=null;
        }else{
          requestData.sendData["QUTN_ID"] = this.QUTN_ID;
        }

        requestData.sendData["TRGT_GUB_CD"]='CUST_TRGT_CD_01'; //사전예약
        requestData.sendData["VIST_GUB_CD"]=this.VIST_GUB_CD; //미방문
        requestData.sendData["USER_ID"]=this.USER_ID;
        requestData.sendData["REG_ID"]=this.USER_ID;
        
        var _ua = window.navigator.userAgent || window.navigator.vgjendor || window.opera;
        requestData.sendData["DEVICE_MODEL"]=_ua;

        //api 호출 부분
        let responseGridList = await this.common_postCall(requestData);
        let resHeader = responseGridList.HEADER;
        this.resBotData = responseGridList.DATA;
        this.resCnslId = responseGridList.DATA_CNSL_ID;
        let resCnslInfos = responseGridList.DATA_CNSL_INFOS;
        let cmpDatas = responseGridList.DATA_CMP_INFO;
        
        // 데이터 조회 오류 시 alert
        if (resHeader.ERROR_FLAG) {

          const msg = this.resBotData[0].MSG;
          this.common_alert(msg, "error");
          this.PASS_REV_BOOL=true;

          if(!this.mixin_isEmpty(responseGridList.HEADER.CLOSE_BOOL) && responseGridList.HEADER.CLOSE_BOOL) {
            this.fnClosePage();
          }
          return false;
          
        }else{
          
          this.PRINT_LIST=[
            {
              LIST_TITLE: '내용',
              LIST_TEXT: this.BUSS_NAME+' 방문예약',
            },
            {
              LIST_TITLE: '기간',
              LIST_TEXT: this.START_DATE + ' ~ ' + this.END_DATE
            },
            {
              LIST_TITLE: '예약',
              LIST_TEXT: this.CHOICE_DAY_TIME,
            },
          ];
          
          if(resCnslInfos.length>0) {
            this.custItem=resCnslInfos;
            this.CMP_CNSL_ID=this.custItem[0].CMP_CNSL_ID;
          }

          if(!this.mixin_isEmpty(cmpDatas)) {
            cmpDatas.forEach((ele, i) => {
              if(!this.mixin_isEmpty(ele.COMP_URL)) {
                this.COMP_LINK = ele.COMP_URL;
              }
            });
          }
    
          //@AFTER 완료 페이지 이동
          this.RESULT_MODE=2;
          this.SAVE_BOOL=true;

          //제출하기 버튼 활성화
          this.PASS_REV_BOOL=true;
          this.sendMessage("VISIT_SCHE_NOTI");
          // this.sendEventApi(this.resBotData, this.resCnslId, this.resInfo);
        }
        
        this.SEL_SHOW=false;
      },

      sendEventApi(resBotData, resCnslId, resInfo){
        let eventName = "eventnew";
        let plusfriendUserKey = this.PLUS_FRED_KEY;
        let apiKey = resBotData[0].BLOCK_API_KEY;
        let botId = resBotData[0].BOT_ID;
        let cmpCnslId = resCnslId[0].CMP_CNSL_ID;
        this.CMP_CNSL_ID = cmpCnslId
        let custPhone = this.CUST_PHONE;
        let bussCode = this.BUSS_CODE;

        let KKO_SKILL_API = process.env.VUE_APP_KKO_SKILL_BACKEND_URL+'/skill/adminEventApi';
      
        $.ajax({
          url: KKO_SKILL_API,
          dataType: "json",
          type: "POST",
          ContentType: "text/plain",
          crossDomain: true,
          data: {
            eventName: eventName,
            plusfriendUserKey: plusfriendUserKey,
            apiKey: apiKey,
            botId: botId,
            cmpCnslId: cmpCnslId,
            custPhone: custPhone,
            bussCode: bussCode,
            resInfo:  resInfo
          },
          success: function (data) {
            console.log('EventApi SUCC : ', data);
          },
          error: function (request, status, error) {
            console.log('EventApi request : ', request);
            console.log('EventApi status : ', status);
            console.log('EventApi error : ', error);
          },
        })

      },

      fnClosePage() {
        setTimeout(() => this.fnCloseBrws(), 1000);
      },

      fnCloseBrws() {
        if(this.mixin_isEmpty(this.COMP_LINK) || !this.mixin_valid_url(this.COMP_LINK)) {
          var _ua = window.navigator.userAgent || window.navigator.vgjendor || window.opera;
          if (_ua.toLocaleLowerCase().indexOf("kakaotalk") > -1) {
            window.location.href = (/iPad|iPhone|iPod/.test(_ua)) ? "kakaoweb://closeBrowser" : "kakaotalk://inappbrowser/close";
          }else{
            window.open("about:blank","_self").close();
          }
        } else {
          window.location.href = this.COMP_LINK;
          window.location.replace(this.COMP_LINK);
          window.open(this.COMP_LINK);
        }     
      },

      fnCloseBrwsAndEVApi() {
        
        //예약변경의 경우 체크하여 알림메세지 분기
        let resBotDt = this.resBotData;
        let resCnsId = this.resCnslId;

        if(resBotDt.length>0 && resCnsId.length>0){
          if(this.IS_REV){
            this.sendEventApi(resBotDt, resCnsId, "MODIFY");
          }else{
            this.sendEventApi(resBotDt, resCnsId, "CHECK");
          }
        }
        setTimeout(() => this.fnCloseBrws(), 777);
      },

      getInitCallBack(dataCmpList, dataCust, dataCustInfo) {
        this.cmpList=dataCmpList;

        //해당 캠페인 사전등록한 고객 데이터
        if(!this.mixin_isEmpty(dataCust)) {
          this.custItem=dataCust;
          let cust=dataCust[0]; //최근 예약한 날짜
          this.CMP_CNSL_ID=cust.CMP_CNSL_ID;

          //설문 참여여부 체크
          if(!this.mixin_isEmpty(this.cmpList[0].N_QUTN_ID) && this.mixin_isEmpty(cust.QUTN_ITEM_LIST)) {
            //설문지가 존재하고 미참여일 경우
            this.$refs.Survey.fnInit();
          }else{
            if(!this.mixin_isEmpty(cust.QUTN_ITEM_LIST)) {
              let custArr = [];
              custArr.push(cust);
              this.fnSurveyCmpAnsDatas(custArr);
            }else{
              this.RESULT_MODE=1;
            }
          }

          if(cust.VIST_GUB_CD=='CNSL_VISIT_CD_01') { //최근 예약한 날짜가 미방문일 경우 예약 수정
            this.custCnt=cust.TOT_CNT;
            if(!this.mixin_isEmpty(cust.CNSL_RSVT_DT)) {
              this.IS_REV=true; //이미 사전등록했던 고객
              const cnslDayTime=cust.CNSL_RSVT_DT.split(' ');

              if(cnslDayTime.length>1) {
                const dateDay=cnslDayTime[0];
                const dateTime=cnslDayTime[1];
                this.BF_CNSL_RSVT_DT=dateDay+' ('+this.getWeekDay(dateDay)+') '+dateTime;
              }
            }

            if(!this.mixin_isEmpty(this.CANCEL_YN)) {
              if(this.CANCEL_YN.toUpperCase()=='Y') {
                this.CANCEL_BOOL=true;
              }
            }

          }else{ //최근 예약한 날짜가 현장 방문완료일 경우 체크
            //현장 방문 후 현장 방문 날짜와 같은 날짜를 또 선택할 경우 예약 불가능 처리
            if(!this.mixin_isEmpty(cust.CNSL_RSVT_DT)) {
              const cnslDayTime=cust.CNSL_RSVT_DT.split(' ');
              if(cnslDayTime.length>1) {
                const dateDay=cnslDayTime[0];
                this.SAME_DATE_CHK=dateDay;
              }
            }
          }
        }else if(!this.mixin_isEmpty(this.cmpList)) {
          if(!this.mixin_isEmpty(this.cmpList[0].N_QUTN_ID)) {
            //미사전예약자이고 설문지가 존재하는 경우
            this.$refs.Survey.fnInit();
          }else{
            this.RESULT_MODE=1;
          }
        }else if(this.mixin_isEmpty(this.cmpList)) {
          this.common_alert('해당 캠페인의 예약시간을 다시 설정해주세요.', 'error');
        }

        this.fnConfirmChk();

        if(dataCustInfo!=null&&dataCustInfo.length>0) {
          if(!this.mixin_isEmpty(dataCustInfo[0].CUST_ID)) {
            dataCustInfo=dataCustInfo[0];
            this.CUST_ID=dataCustInfo.CUST_ID;
            this.CUST_NAME=dataCustInfo.CUST_NAME;
            this.BUSS_CODE=dataCustInfo.BUSS_CODE;
          }else{
            this.common_alert(dataCustInfo[1].MSG);
            this.fnClosePage();
          }
        }
        
        if(this.cmpList!==null&&this.cmpList.length>0) {
          const todayDate=new Date();

          let yyyy=todayDate.getFullYear();
          let mm=todayDate.getMonth()+1>9?(todayDate.getMonth()+1):'0'+(todayDate.getMonth()+1);
          let dd=todayDate.getDate()>9?todayDate.getDate():'0'+todayDate.getDate();

          for (let index = 0; index < this.cmpList.length; index++) {
            const cmp = this.cmpList[index];
            this.dateArr.push(cmp.RESERV_DAY);

            if(index===0) {
              let week=this.getWeekDay(this.dateArr[index]);
              this.START_DATE=this.dateArr[index] + ' ('+week+') ' + cmp.TIME_RANGE_LIST.split(',')[0];

              this.BUSS_NAME=cmp.BUSS_NAME;

              this.DATE_MIN=this.dateArr[index];

              const cmpDate=new Date(this.DATE_MIN);
              if(todayDate>cmpDate){
                let lastTime='';
                for (let i = 0; i < this.cmpList.length; i++) {
                  const element = this.cmpList[i];
                  if(element.RESERV_DAY===yyyy+'-'+mm+'-'+dd){
                    const timeRList=element.TIME_RANGE_LIST.split(',');
                    lastTime=timeRList[timeRList.length-1]+':00';
                  }
                }
    
                const cmpLastTime=new Date(yyyy+'-'+mm+'-'+dd+' '+lastTime);
                if(todayDate>cmpLastTime) {
                  dd=todayDate.getDate()>9?todayDate.getDate()+1:'0'+(todayDate.getDate()+1);
                }
                this.DATE_MIN=yyyy+'-'+mm+'-'+dd;
              }

              if(cmp.REMA_NUM_PEP_ON_OFF=='Y') {
                this.REMA_NUM_PEP_ON_OFF=true;
              }else{
                this.REMA_NUM_PEP_ON_OFF=false;
              }
            }
            
            if(index===this.cmpList.length-1) {
              this.DATE_MAX=this.dateArr[index];

              let week=this.getWeekDay(this.dateArr[index]);
              let timeRangeArr=cmp.TIME_RANGE_LIST.split(',');
              this.END_DATE=this.dateArr[index] + ' ('+week+') ' + timeRangeArr[timeRangeArr.length-1];
            }

            let obj={
              reservDay:this.dateArr[index],
              timeRangeList:cmp.TIME_RANGE_LIST,
              cnslTimeUnit:cmp.CNSL_TIME_UNIT,
              totalReservPers:cmp.TOTAL_RESERV_PERS,
              maxReservTimeCnt:cmp.MAX_RESERV_TIME_CNT,
              qutnId:cmp.QUTN_ID,
            };
            this.dayByTimeManArr[this.dateArr[index]]=obj;
          }

          const maxDate=new Date(this.DATE_MAX+' 23:59:59');

          //캠페인 종료날짜가 현재 날짜보다 전일 경우 창 종료
          if(todayDate>maxDate) {
            this.common_alert(this.alertMsg.endCampMsg);
            console.log("1111")
            // this.fnClosePage();
          }
          
        }else{
          //창 종료
          this.common_alert(this.alertMsg.endCampMsg);
          console.log("2222")
          // this.fnClosePage();
        }
      },

      fnSurveySave(surveyAfSave) {
        this.SURVEY_AF_DATAS=surveyAfSave;

        //사전예약 및 설문데이터 저장 테이블 key sett
        if(this.SURVEY_AF_DATAS.length>0) {
          this.SURVEY_AF_DATAS.forEach((element, i) => {
            this.NEW_CMP_CNSL_ID=element.CMP_CNSL_ID;
          });
        }

        this.RESULT_MODE=1;
      },

      //해당 캠페인에 설문 존재여부
      fnSurveyCmpDatas(datas) {
        if(this.mixin_isEmpty(datas.QUTN_ID)) {
          this.BTN_BOOL=false;
          this.RESULT_MODE=1;
        }else{
          this.QUTN_ID=datas.QUTN_ID;
        }
      },

      //인입시 설문답 데이터 유무
      fnSurveyCmpAnsDatas(surveyCmpAnsDatas) {
        if(!this.mixin_isEmpty(surveyCmpAnsDatas)) {
          if(surveyCmpAnsDatas.length>0) {
            surveyCmpAnsDatas.forEach((element, i) => {
              this.QUTN_ID=element.QUTN_ID;
              this.QUTN_ITEM_LIST=element.QUTN_ITEM_LIST;
              this.CHK_QUTN_RPLY=element.CHK_QUTN_RPLY;
              this.SEL_QUTN_RPLY=element.SEL_QUTN_RPLY;
              this.TXT_QUTN_RPLY=element.TXT_QUTN_RPLY;
              this.ETC_QUTN_RPLY=element.ETC_QUTN_RPLY;
              this.IMG_QUTN_RPLY=element.IMG_QUTN_RPLY;
              this.DATE_QUTN_RPLY=element.DATE_QUTN_RPLY;
              this.UPLD_GROUP_ID=element.UPLD_GROUP_ID;
              this.PART_TYPE=element.PART_TYPE;
              this.CTCP_CODE=element.CTCP_CODE;
              this.BUSS_CODE=element.BUSS_CODE;
            });
          }
          this.BTN_BOOL=false;
          this.RESULT_MODE=1;
        }else{
          if(!this.mixin_isEmpty(this.QUTN_ID)) {
            this.RESULT_MODE=0;
          }
        }
      },

      fnBackSurvey() {
        this.RESULT_MODE=0;
      },
    },

    computed: {
      ...mapState({
        stateToken: "authStore/token",
      }),
      initHeaders(){
        return {
          SERVICE : 'hlw.campaign.manage',
          METHOD : "",
          TYPE : 'BIZ_SERVICE',
        };
      },
      paramData(){
        return {
        };
      }
    },
  };
</script>

<style>
  .v-btn--disabled {
    background-color: #fff !important;
  }
  
  .mo-reserve-wrap {
    font-family: 'Spoqa Han Sans Neo', 'sans-serif';
  }
</style>